<template>
  <footer class="footer-area">
    <div class="footer-widget">
    <div class="container">
    <div class="row footer-widget-wrapper pt-100 pb-70">
    <div class="col-md-6 col-lg-3">
    <div class="footer-widget-box about-us">
    <a href="#" class="footer-logo">
    <img src="/assets/img/logo/logo-light.png" alt>
    </a>
    <p class="mb-1">
    We are many variations of the passages available but the majoro have suffered alteration
    in some form by injected.
    </p>
    <div class="footer-call">
    <div class="footer-call-icon">
    <i class="fal fa-headset"></i>
    </div>
    <div class="footer-call-info">
    <p>24/7 Need Any Help?</p>
    <a href="tel:+21236547898">(+2) 222 666 7777</a>
    </div>
    </div>
    <div class="footer-download">
    <h5>Get Our Mobile App</h5>
    <div class="footer-download-btn">
    <a href="#">
    <i class="fab fa-google-play"></i>
    <div class="download-btn-info">
    <span>Get It On</span>
    <h6>Google Play</h6>
    </div>
    </a>
    <a href="#">
    <i class="fab fa-app-store"></i>
    <div class="download-btn-info">
    <span>Get It On</span>
    <h6>App Store</h6>
    </div>
    </a>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-2">
    <div class="footer-widget-box list">
    <h4 class="footer-widget-title">Quick Links</h4>
    <ul class="footer-list">
    <li><a href="#">About Us</a></li>
    <li><a href="#">Become A Seller</a></li>
    <li><a href="#">Contact Us</a></li>
    <li><a href="#">Update News</a></li>
    <li><a href="#">Testimonials</a></li>
    <li><a href="#">Terms Of Service</a></li>
    <li><a href="#">Privacy policy</a></li>
    </ul>
    </div>
    </div>
    <div class="col-md-6 col-lg-2">
    <div class="footer-widget-box list">
    <h4 class="footer-widget-title">Browse Category</h4>
    <ul class="footer-list">
    <li><a href="#">Accessories</a></li>
    <li><a href="#">Home & Garden</a></li>
    <li><a href="#">Electronics</a></li>
    <li><a href="#">Health & Beauty</a></li>
    <li><a href="#">Groceries</a></li>
    <li><a href="#">Baby Toys</a></li>
    <li><a href="#">Music & Video</a></li>
    </ul>
    </div>
    </div>
    <div class="col-md-6 col-lg-2">
    <div class="footer-widget-box list">
    <h4 class="footer-widget-title">Support Center</h4>
    <ul class="footer-list">
    <li><a href="#">FAQ's</a></li>
    <li><a href="#">How To Buy</a></li>
    <li><a href="#">Support Center</a></li>
    <li><a href="#">Track Your Order</a></li>
    <li><a href="#">Returns Policy</a></li>
    <li><a href="#">Our Affiliates</a></li>
    <li><a href="#">Sitemap</a></li>
    </ul>
    </div>
    </div>
    <div class="col-md-6 col-lg-3">
    <div class="footer-widget-box list">
    <h4 class="footer-widget-title">Get In Touch</h4>
    <p>Feel Free To Get In Touch With Us Today. We Are Ready To Help You.</p>
    <ul class="footer-contact">
    <li><a href="tel:+21236547898"><i class="far fa-phone"></i>+2 123 654 7898</a></li>
    <li><i class="far fa-map-marker-alt"></i>25/B Milford Road, New York</li>
    <li><a href="/cdn-cgi/l/email-protection#bcd5d2dad3fcd9c4ddd1ccd0d992dfd3d1"><i class="far fa-envelope"></i><span class="__cf_email__" data-cfemail="1871767e77587d60797568747d367b7775">[email&#160;protected]</span></a></li>
    <li><i class="far fa-clock"></i>Mon-Fri (9.00AM - 8.00PM)</li>
    </ul>
    </div>
    </div>
    <div class="col-12">
    <div class="footer-top-link">
    <h5>Top Links</h5>
    <div class="footer-top-link-info">
    <a href="#">Top Sellers</a>
    <a href="#">New Arrivals</a>
    <a href="#">Accessories</a>
    <a href="#">Electronics</a>
    <a href="#">Groceries</a>
    <a href="#">Beauty</a>
    <a href="#">Health</a>
    <a href="#">Baby Toys</a>
    <a href="#">Music</a>
    <a href="#">Furniture</a>
    <a href="#">Gifts</a>
    <a href="#">Sports</a>
    <a href="#">Automotive</a>
    <a href="#">Watch</a>
    <a href="#">Sitemap</a>
    <a href="#">Our Affiliates</a>
    <a href="#">Returns Policy</a>
    <a href="#">Video</a>
    <a href="#">Home & Garden</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="copyright">
    <div class="container">
    <div class="copyright-wrap">
    <div class="row">
    <div class="col-12 col-lg-4 align-self-center">
    <div class="footer-payment">
    <span>We Accept:</span>
    <img src="/assets//img/payment/visa.svg" alt>
    <img src="/assets//img/payment/mastercard.svg" alt>
    <img src="/assets//img/payment/amex.svg" alt>
    <img src="/assets//img/payment/discover.svg" alt>
    <img src="/assets//img/payment/paypal.svg" alt>
    </div>
    </div>
    <div class="col-12 col-lg-4 align-self-center">
    <p class="copyright-text">
    &copy; Copyright <span id="date"></span> <a href="https://gradinaflorilor.ro"> Grădina Florilor </a> Toate drepturile rezervate.
    </p>
    </div>
    <div class="col-12 col-lg-4 align-self-center">
    <div class="footer-social">
    <span>Follow Us:</span>
    <a href="#"><i class="fab fa-facebook-f"></i></a>
    <a href="#"><i class="fab fa-twitter"></i></a>
    <a href="#"><i class="fab fa-linkedin-in"></i></a>
    <a href="#"><i class="fab fa-youtube"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </footer>
    
    
    <a href="#" id="scroll-top"><i class="far fa-arrow-up-from-arc"></i></a>
    
    
    <div class="modal quickview fade" id="quickview" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="quickview" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="far fa-xmark"></i></button>
    <div class="modal-body">
    <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <img src="/assets/img/product/p11.png" alt="#">
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <div class="quickview-content">
    <h4 class="quickview-title">Beats Bluetooth Earphones</h4>
    <div class="quickview-rating">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star-half-alt"></i>
    <i class="far fa-star"></i>
    <span class="rating-count"> (4 Customer Reviews)</span>
    </div>
    <div class="quickview-price">
    <h5><del>$690</del><span>$650</span></h5>
    </div>
    <ul class="quickview-list">
    <li>Brand:<span>Beats</span></li>
    <li>Category:<span>Headphones</span></li>
    <li>Stock:<span class="stock">Available</span></li>
    <li>Code:<span>676TYWV</span></li>
    </ul>
    <div class="quickview-cart">
    <a href="#" class="theme-btn">Add to cart</a>
    </div>
    <div class="quickview-social">
    <span>Share:</span>
    <a href="#"><i class="fab fa-facebook-f"></i></a>
    <a href="#"><i class="fab fa-twitter"></i></a>
    <a href="#"><i class="fab fa-pinterest-p"></i></a>
    <a href="#"><i class="fab fa-instagram"></i></a>
    <a href="#"><i class="fab fa-linkedin-in"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="modal popup-banner fade" id="popup-banner" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
    <button type="button" class="btn-close" data-bs-dismiss="modal"><i class="far fa-xmark"></i></button>
    <div class="modal-body">
    <div class="popup-banner-content">
    <div class="row">
    <div class="col-lg-6">
    <div class="popup-banner-img">
    <img src="/assets/img/banner/popup-banner.jpg" alt>
    </div>
    </div>
    <div class="col-lg-6 align-self-center">
    <div class="popup-banner-info">
    <h6>7 Days Super Sale !</h6>
    <h2>Hurry Up! Get Up To <span>50%</span> Discount</h2>
    <p>There are many variations the majority have suffered alteration in some form injected words look even slightly believable.</p>
    <a href="#" class="theme-btn">Start Shop Now</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>

</template>

<script>

import { defineComponent } from 'vue';

export default {
  name: 'Footer',
  props: {
  
  },
  methods: {
    request() {

      let requestData = 
      {
        action:"testRequest",
        parameterTest: 'SEND TEST4'
      }

       mainJs.postRequest(requestData).then((res) => {
        console.log(res);
      })
      
     
    }
  },
  mounted() {
  
  }
}
</script>

<style>
</style>
