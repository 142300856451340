<template>
    <div v-show="show" class="overlay transitionFast">

      <div class="dialog">
  
        <div class="dialog__content">
          <h2 class="dialog__title" v-text="title"></h2>
          <hr />
          <p class="dialog__description" v-html="description"></p>
        </div>
  
        <div class="dialog__footer">
          <button @click="cancel" class="dialog__cancel">{{noButton}}</button>
          <button @click="confirm" class="dialog__confirm">{{okButton}}</button>
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
      props: ['okButton','noButton','show', 'title', 'description', 'cancel', 'confirm']
  }
  </script>

<style scoped>

  .btn {
        --tw-bg-opacity: 1;
        background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
        border-radius: 0.75rem;
        font-weight: 500;
        margin-right: 1rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
        -webkit-user-select: none;
          -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
               
      }
    .btn:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
      --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .btn:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      --tw-ring-opacity: 1;
      --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
      --tw-ring-opacity: 0.5;
    }
    .overlay {
      --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
      --tw-bg-opacity: 0.5;
      height: 100%;
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 10;
    }
    .dialog {
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      border-radius: 0.75rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 9.5rem;
      min-width: 352px;
      width: 25%;
    }
    
    .dialog__content {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .dialog__title {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
      --tw-text-opacity: 1;
      color: #be3c15;
      text-align: center;
    }
    .dialog__description {
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 0 5%;
      margin-bottom: 1rem;
      --tw-text-opacity: 1;
      color: rgba(107, 114, 128, var(--tw-text-opacity));
      text-align: center;
    }
    .dialog__footer {
      display: flex;
      column-gap: 46%;
      justify-content: center;
    
      padding-bottom: 1rem;
    }
    .dialog__cancel {
      border: none;
      border-radius: 0.5rem;
      font-weight: bold;
    }
    .dialog__cancel:focus{
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
    .dialog__cancel{
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .dialog__cancel:focus{
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      --tw-ring-opacity: 1;
      --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
      --tw-ring-opacity: 0.5;
    }
    .dialog__cancel{
      --tw-text-opacity: 1;
      background-color: #be3c15;
      color: white;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    .dialog__cancel:hover {
      background-color: #be3c15bd;
      --tw-text-opacity: 1;
      color: rgba(55, 65, 81, var(--tw-text-opacity));
    }
    .dialog__confirm {
      border: none;
      --tw-bg-opacity: 1;
      background-color: #90ee90;
      border-radius: 0.6rem;
      font-weight: bold;
      width: 91px;
    }
    .dialog__confirm:focus{
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
    .dialog__confirm{
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .dialog__confirm:focus{
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      --tw-ring-opacity: 1;
      --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
      --tw-ring-opacity: 0.5;
    }
    .dialog__confirm{
      --tw-text-opacity: 1;
      color: rgba(220, 38, 38, var(--tw-text-opacity));
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    .dialog__confirm:hover {
      --tw-bg-opacity: 1;
      background-color: #1eb400;
      --tw-text-opacity: 1;
      color: white;
    }
    @media (min-width: 640px){
    }
    @media (min-width: 768px){
    }
    @media (min-width: 1024px){
    }
    @media (min-width: 1280px){
    }
    @media (min-width: 1536px){
    }

</style>