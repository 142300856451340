import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginUser from '../views/LoginUser.vue'
import UserDashboard from '../views/UserDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      authRequired: 'false',
    }
  },
  {
    path: '/login-area',
    name: 'login',
    component: LoginUser,
    meta: {
      authRequired: 'false',
    }
  },
  {
    path: '/user-dashboard',
    name: 'user-dashboard',
    component: UserDashboard,
    meta: {
      authRequired: 'true',
    }
  },
  {
    path: '/user-dashboard/categorii',
    name: 'user-dashboard-categorii',
    component: UserDashboard,
    props: route => ({
      tipComponenta: "Categories"
    }),
  
    meta: {
      authRequired: 'true',
    }
  },
  {
    path: '/user-dashboard/atribute',
    name: 'user-dashboard-atribute',
    component: UserDashboard,
    props: route => ({
      tipComponenta: "Attributes"
    }),
  
    meta: {
      authRequired: 'true',
    }
  },
  {
    path: '/user-dashboard/categorii/:idCateg',
    name: 'user-dashboard-categorii-id',
    component: UserDashboard,
    props: route => ({
      tipComponenta: "Categories",
      routeIdCateg: route.params.idCateg
    }),
  
    meta: {
      authRequired: 'true',
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to,from,next) => {
  let usrToken = localStorage.getItem("userToken")

  if (to.meta.authRequired === 'true') {

    // axios.post(php_url.php_url,JSON.stringify({action:"ckAccess"})).then((res) => {
    //   let access = Number(res.data.access)


      if (usrToken) {
        next();
      } else {
        router.push({
          name: 'login'
        })
      }
    // })
  } else {
  
    if(to.name === 'login' && usrToken) {
      router.push({
        name: 'user-dashboard'
      })
    } else {
      next();
    }
  }
 
});

export default router
