<template>
  <router-view/>
</template>


<style>
.transition {
  animation: fadeInAnimation ease 1.5s
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.transitionFast {
  animation: fadeInAnimation ease 1s
}

</style>
