<template>
  <AddAttributes v-if="showAddAttributes" :show="showAddAttributes" 
    okButton="Ok"
    noButton="Închide"
    :cancel="closeAddAttribute"
    confirm="confirm"
    title="Adaugă atribut!"
    :attributeId=attributeId
    :attributeName=attributeName
    :attributeStatus=attributeStatus
    
     />

     <ConfirmDialog v-if="showDeleteAttribute" :show="showDeleteAttribute" 
     okButton="Da"
     noButton="Închide"
     :cancel="closeDeleteAttribute"
     :confirm="DeleteAttribute"
     title="Atenție !!!"
     :description=description
     
      />

      <MessageDialog v-if="showMessageDialog" :show="showMessageDialog" 
      okButton="Ok"
      noButton="Închide"
      :cancel="closeMessageDialog"
      title="Atenție !!!"
      :description=description
      
       />

     <div class="user-wrapper">
      <div class="row">
        <div class="col-lg-12">
          
          <div class="user-card user-product">
            <div class="user-card-header">
              <h4 class="user-card-title">Atribute</h4>
              <div class="user-card-header-right">
                
                
                <a href="#" class="theme-btn" @click.prevent="AddAttribute('')">Adaugă Atribute</a>
              </div>
            </div>
            <div class="table-responsive">
             
              <table class="table table-borderless text-nowrap">
                <thead>
                  <tr>
                    <th style="width: 30%;"><a href="#" @click.prevent="goBack"class="btn btn-danger me-4">Înapoi</a> Atribut</th>
                    
                    <th class="text-center">Status</th>
                    <th class="text-center">Acțiune</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="attr in attributes" :key="attr.id" :value="attr.id" :parent="attr.parent">
                    <td>
                      <div class="table-list-info">
                        <a :href="`/user-dashboard/attributes/${attr.id}`">
                          <div class="table-list-img">
                            <img
                              src="/assets/img/product/p1.png"
                              alt
                            />
                          </div>
                          <div class="table-list-content">
                            <h6>{{attr.name}}</h6>
                          
                          </div>
                        </a>
                      </div>
                    </td>
                    
                    <td class="text-center">
                      <span v-if="attr.status == 1" class="badge badge-success">Activ</span>
                      <span v-if="attr.status == 2" class="badge badge-danger">Dezactivat</span>
                    </td>
                    <td class="text-center">
                      <!-- @click.prevent="getSubcategory(categ.id,categ.name)" -->
                      <a :href="`/user-dashboard/atribut/${attr.id}`"
                        class="btn btn-outline-secondary btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Details"
                        ><i class="far fa-eye"></i
                      ></a>
                      <a @click.prevent="AddAttribute(attr.id,attr.name,attr.status)"
                        href="#"
                        class="btn btn-outline-secondary btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Edit"
                        ><i class="far fa-pen"></i
                      ></a>
                      <a @click.prevent="DeleteAttributeModal(attr.id,attr.name)"
                        href="#"
                        class="btn btn-outline-danger btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Șterge atributul"
                        ><i class="far fa-trash-can"></i
                      ></a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>

 
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios"
import mainJs from "../../main.js"

import AddAttributes from '../modals/AddAttributes.vue'
import ConfirmDialog from '../modals/ConfirmDialog.vue'
import MessageDialog from '../modals/MessageDialog.vue'

export default {
  components: {AddAttributes,ConfirmDialog,MessageDialog},
  // emits: ["parameters-sent"],
  inheritAttrs: false,
  name: "AttributesDashboard",
  data() {
        return {
          showAddAttributes: false,
          showDeleteAttribute: false,
          showMessageDialog: false,
          attributeType1: "",
          attributeType2: "",
          description: "",
          attributeId: null,
          parentId: "",
          attributeName: "",
          attributeStatus: "1",
          attributes: []
  
        }
      },
  
  props: ['idAttribute'],
  methods: {
   
    goBack() {
      window.history.back();
    },
    getAttributes() {
     
          let requestData = 
            {
              action:"getAttributes"
            }

          mainJs.postRequest(requestData).then((res) => {
            // let filteredArray = res.categories.filter(item => item.name != this.categName);

            this.attributes = res.attributes
          
            // console.log(this.categories);
          })
        },
    AddAttribute(id,name,status) {
    
          this.showAddAttributes = true
          if(!id) {
            this.attributeId = null;
            this.attributeName = "";
            this.attributeStatus = "1";
          } else {
            this.attributeId = id;
            this.attributeName = name;
            this.attributeStatus = status;
          }
        },
        DeleteAttributeModal(id,name) {
    
          this.showDeleteAttribute = true
          this.description = "Sigur dorești să ștergi atributul <br><span style='color: red;'>" + name + "</span> ?"
          if(!id) {
            this.attributeId = null;
            this.attributeName = "";
          } else {
            this.attributeId = id;
            this.attributeName = name;
          }
        },
        DeleteAttribute() {
          
          this.showDeleteAttribute = false
          if(this.categId) {
            let requestData = 
            {
              action:"deleteAttribute",
              categ_id: this.attributeId
            }

            mainJs.postRequest(requestData).then((res) => {
              if(res == 1) {
                this.getAttributes()
              } else if(res == 2) {
                this.description = "Atributul <span style='color: red;'>" + this.categName + "</span> are itemi salvați!"
                this.showMessageDialog = true
              }
            })
          }
         
        },
    closeAddAttribute() {
      this.showAddAttributes = false
      this.getAttributes()
    },
    closeDeleteAttribute() {
      this.showDeleteAttribute = false
    },
    closeMessageDialog() {
      this.showMessageDialog = false
    },
  
  },
  mounted() {
  
    // if(this.idAttribute) {
    //   // this.categId = this.idCateg
    //   this.attributeType1 = "Subcategorii";
    //   this.attributeType2 = "Subcategoria";
    // } else {
    //   this.categType1 = "Atribute";
    //   this.categType2 = "Atributul";
    // }
    this.getAttributes()
  },
};
</script>

<style></style>
