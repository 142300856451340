<template>
    
  <main class="main">

    <div class="hero-section">
    <div class="container">
    <div class="hero-slider owl-carousel owl-theme">
    <div class="hero-single">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-3">
    <div class="hero-content">
    <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Up To 50% Off</h6>
    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
    For Your First <br><span>Goemart</span> Shopping
    </h1>
    <p data-animation="fadeInLeft" data-delay=".75s">
    There are many variations of passages orem psum available but the majority have
    suffered alteration in some form by injected humour.
    </p>
    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
    <a href="#" class="theme-btn">Shop Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
    <div class="hero-right">
    <div class="hero-img">
    <img src="/assets/img/slider/hero-1.png" alt data-animation="fadeInRight" data-delay=".25s">
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="hero-single">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-3">
    <div class="hero-content">
    <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Up To 50% Off</h6>
    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
    For Your First <br><span>Goemart</span> Shopping
    </h1>
    <p data-animation="fadeInLeft" data-delay=".75s">
    There are many variations of passages orem psum available but the majority have
    suffered alteration in some form by injected humour.
    </p>
    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
    <a href="#" class="theme-btn">Shop Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
    <div class="hero-right">
    <div class="hero-img">
    <img src="/assets/img/slider/hero-2.png" alt data-animation="fadeInRight" data-delay=".25s">
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="hero-single">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-3">
    <div class="hero-content">
    <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Up To 50% Off</h6>
    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
    For Your First <br><span>Goemart</span> Shopping
    </h1>
    <p data-animation="fadeInLeft" data-delay=".75s">
    There are many variations of passages orem psum available but the majority have
    suffered alteration in some form by injected humour.
    </p>
    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
    <a href="#" class="theme-btn">Shop Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
    <div class="hero-right">
    <div class="hero-img">
    <img src="/assets/img/slider/hero-3.png" alt data-animation="fadeInRight" data-delay=".25s">
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="hero-single">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-md-12 col-lg-6 col-xl-5 offset-xl-3">
    <div class="hero-content">
    <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Up To 50% Off</h6>
    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
    For Your First <br><span>Goemart</span> Shopping
    </h1>
    <p data-animation="fadeInLeft" data-delay=".75s">
    There are many variations of passages orem psum available but the majority have
    suffered alteration in some form by injected humour.
    </p>
    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
    <a href="#" class="theme-btn">Shop Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
    <div class="hero-right">
    <div class="hero-img">
    <img src="/assets/img/slider/hero-4.png" alt data-animation="fadeInRight" data-delay=".25s">
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="small-banner pt-100">
    <div class="container">
    <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
    <div class="banner-item">
    <img src="/assets/img/banner/mini-banner-1.jpg" alt>
    <div class="banner-content">
    <p>Hot Collections</p>
    <h3>Best Travel Sale <br> Collections</h3>
    <a href="#">Discover Now</a>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
    <div class="banner-item">
    <img src="/assets/img/banner/mini-banner-2.jpg" alt>
    <div class="banner-content">
    <p>Apple Collections</p>
    <h3>Apple Smart Watch <br> Collectons</h3>
    <a href="#">Shop Now</a>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
    <div class="banner-item">
    <img src="/assets/img/banner/mini-banner-3.jpg" alt>
    <div class="banner-content">
    <p>Shoe Collections</p>
    <h3>Summer Season <br> Shoe Up To <span>50%</span> Off</h3>
    <a href="#">Discover Now</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="category-area pt-70 pb-90">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/category.svg" alt>Top Category</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="row g-2">
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/dress.svg" alt>
    </div>
    <div class="content">
    <h4>Accessories</h4>
    <p>30 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/laptop.svg" alt>
    </div>
    <div class="content">
    <h4>Electronics</h4>
    <p>25 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/grocery.svg" alt>
    </div>
    <div class="content">
    <h4>Grocery & Market</h4>
    <p>15 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/music.svg" alt>
    </div>
    <div class="content">
    <h4>Music & Audio</h4>
    <p>05 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/furniture.svg" alt>
    </div>
    <div class="content">
    <h4>Furniture</h4>
    <p>30 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/toy.svg" alt>
    </div>
    <div class="content">
    <h4>Toy & Video Games</h4>
    <p>12 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/gifts.svg" alt>
    </div>
    <div class="content">
    <h4>Gifts</h4>
    <p>08 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/mother.svg" alt>
    </div>
    <div class="content">
    <h4>Babies & Moms</h4>
    <p>14 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/beauty.svg" alt>
    </div>
    <div class="content">
    <h4>Health & Beauty</h4>
    <p>19 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/sports.svg" alt>
    </div>
    <div class="content">
    <h4>Sports & Outdoor</h4>
    <p>24 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/garden.svg" alt>
    </div>
    <div class="content">
    <h4>Home & Garden</h4>
    <p>09 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    <div class="col-6 col-md-4 col-lg-2">
    <div class="category-item">
    <a href="#">
    <div class="category-info">
    <div class="icon">
    <img src="/assets/img/icon/automotive.svg" alt>
    </div>
    <div class="content">
    <h4>Automotive</h4>
    <p>16 Items</p>
    </div>
    </div>
    </a>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/trending.svg" alt>Trending Items</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="product-slider owl-carousel owl-theme">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p1.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type hot">Hot</span>
    <a href="shop-single.html"><img src="/assets/img/product/p3.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type oos">Out Of Stock</span>
    <a href="shop-single.html"><img src="/assets/img/product/p4.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p5.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <a href="shop-single.html"><img src="/assets/img/product/p6.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="mid-banner pt-100 pb-60">
    <div class="container">
    <div class="row">
    <div class="col-12 col-md-6">
    <div class="banner-item">
    <img src="/assets/img/banner/mid-banner-1.jpg" alt>
    <div class="banner-content">
    <p>Top Collections</p>
    <h3>Women's Fashion Up <br> To <span>40%</span> Off</h3>
    <a href="#" class="theme-btn">Shop Now</a>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6">
    <div class="banner-item">
    <img src="/assets/img/banner/mid-banner-2.jpg" alt>
    <div class="banner-content">
    <p>Headphone Sale</p>
    <h3>Headphone Sale Up <br> To <span>50%</span> Off</h3>
    <a href="#" class="theme-btn">Shop Now</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/hot.svg" alt>Hot Items</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="product-slider owl-carousel owl-theme">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p7.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type hot">Hot</span>
    <a href="shop-single.html"><img src="/assets/img/product/p8.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type oos">Out Of Stock</span>
    <a href="shop-single.html"><img src="/assets/img/product/p12.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p14.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <a href="shop-single.html"><img src="/assets/img/product/p11.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area pt-90 pb-100">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/new.svg" alt>New Arrivals</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="product-slider owl-carousel owl-theme">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p31.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p33.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p37.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p44.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p48.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="big-banner">
    <div class="container">
    <div class="banner-wrap" style="background-image: url(/assets/img/banner/big-banner.jpg);">
    <div class="row">
    <div class="col-lg-8 mx-auto">
    <div class="banner-content">
    <div class="banner-info">
    <h6>Mega Collections</h6>
    <h2>Huge Sale Up To <span>40%</span> Off</h2>
    <p>at our outlet stores</p>
    </div>
    <a href="#" class="theme-btn">Shop Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="brand-area pt-80">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/brand.svg" alt>Popular Brands</h2>
    <a href="#">All Brands <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="brand-slider owl-carousel owl-theme">
    <div class="brand-item">
    <img src="/assets/img/brand/01.png" alt>
    </div>
    <div class="brand-item">
    <img src="/assets/img/brand/02.png" alt>
    </div>
    <div class="brand-item">
    <img src="/assets/img/brand/03.png" alt>
    </div>
    <div class="brand-item">
    <img src="/assets/img/brand/04.png" alt>
    </div>
    <div class="brand-item">
    <img src="/assets/img/brand/05.png" alt>
    </div>
    <div class="brand-item">
    <img src="/assets/img/brand/06.png" alt>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area py-100">
    <div class="container">
    <div class="row">
    <div class="col-lg-3 mb-5">
    <div class="product-brand">
    <h5>Phone Brands</h5>
    <div class="brand-list">
    <a href="#"><i class="far fa-objects-column"></i>Samsung<span>(10)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Apple<span>(15)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Xiaomi<span>(12)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Sony<span>(18)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Google<span>(05)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Vivo<span>(14)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Huawei<span>(17)</span></a>
    <a href="#"><i class="far fa-objects-column"></i>Techno<span>(08)</span></a>
    </div>
    <a href="#" class="theme-btn">View All Brands<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    <div class="col-lg-9">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/product-list.svg" alt>Mobile Phones</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p15.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <span class="type hot">Hot</span>
    <a href="shop-single.html"><img src="/assets/img/product/p16.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <span class="type oos">Out Of Stock</span>
    <a href="shop-single.html"><img src="/assets/img/product/p17.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p18.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <a href="shop-single.html"><img src="/assets/img/product/p19.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="product-item">
    <div class="product-img">
    <a href="shop-single.html"><img src="/assets/img/product/p20.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/discount.svg" alt>Big Discount Items</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="product-slider owl-carousel owl-theme">
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p11.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p30.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p36.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p52.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p56.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p57.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="seller-area py-100">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/best-seller.svg" alt>Best Sellers</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/01.png" alt></a>
    </div>
    <a href="#" class="seller-title">Fast Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/02.png" alt></a>
    </div>
    <a href="#" class="seller-title">Borcelle Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/03.png" alt></a>
    </div>
    <a href="#" class="seller-title">Fradel Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/04.png" alt></a>
    </div>
    <a href="#" class="seller-title">Liceria Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/05.png" alt></a>
    </div>
    <a href="#" class="seller-title">Fashion Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/06.png" alt></a>
    </div>
    <a href="#" class="seller-title">Quick Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/07.png" alt></a>
    </div>
    <a href="#" class="seller-title">Sebastin Shop</a>
    </div>
    </div>
    <div class="col">
    <div class="seller-item">
    <div class="seller-img">
    <a href="#"><img src="/assets/img/seller/08.png" alt></a>
    </div>
    <a href="#" class="seller-title">Jiorox Shop</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="deal-area">
    <div class="container-fluid p-0">
    <div class="row g-0">
    <div class="col-12 col-lg-6">
    <div class="deal-img">
    <img src="/assets/img/deal/01.jpg" alt="#">
    </div>
    </div>
    <div class="col-12 col-lg-6 align-self-center">
    <div class="deal-content">
    <span class="deal-sub-title">Todays Deal 20% Off</span>
    <h3 class="deal-title">Summer Beach Bag With Elegant Women's Accessories.</h3>
    <p class="deal-text">There are many variations of passages available but the majority have suffered alteration in some form words look even slightly believable.</p>
    <h3 class="deal-price"><span>$1020</span><del>$1200</del></h3>
    <div class="col-lg-9">
    <div class="deal-countdown">
    <div class="countdown" data-countdown="2030/12/30"></div>
    </div>
    </div>
    <div class="deal-btn">
    <a href="#" class="theme-btn">Buy Now<i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-list pt-100 pb-90">
    <div class="container">
    <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
    <div class="product-list-box">
    <h2 class="product-list-title">On sale</h2>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p39.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p2.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p3.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
    <div class="product-list-box">
    <h2 class="product-list-title">Best Seller</h2>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p4.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p5.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p6.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
    <div class="product-list-box">
    <h2 class="product-list-title">Top Rated</h2>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p7.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p8.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    <div class="product-list-item">
    <div class="product-list-img">
    <a href="shop-single.html"><img src="/assets/img/product/p26.png" alt="#"></a>
    </div>
    <div class="product-list-content">
    <h4><a href="shop-single.html">Awesome Police Eyeglass</a></h4>
    <div class="product-list-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-list-price">
    <del>60.00</del><span>$40.00</span>
    </div>
    </div>
    <a href="#" class="product-list-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart"><i class="far fa-shopping-bag"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="feature-area pt-50 pb-50">
    <div class="container">
    <div class="row">
    <div class="col-12 col-md-6 col-lg-3">
    <div class="feature-item">
    <div class="feature-icon">
    <i class="fal fa-truck"></i>
    </div>
    <div class="feature-content">
    <h4>Free Delivery</h4>
    <p>Orders Over $120</p>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
    <div class="feature-item">
    <div class="feature-icon">
    <i class="fal fa-sync"></i>
    </div>
    <div class="feature-content">
    <h4>Get Refund</h4>
    <p>Within 30 Days Returns</p>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
    <div class="feature-item">
    <div class="feature-icon">
    <i class="fal fa-wallet"></i>
    </div>
    <div class="feature-content">
    <h4>Safe Payment</h4>
    <p>100% Secure Payment</p>
    </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
    <div class="feature-item">
    <div class="feature-icon">
    <i class="fal fa-headset"></i>
    </div>
    <div class="feature-content">
    <h4>24/7 Support</h4>
    <p>Feel Free To Call Us</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="product-area py-100">
    <div class="container">
    <div class="row">
    <div class="col-12">
    <div class="site-heading-inline">
    <h2 class="site-title"><img src="/assets/img/icon/more.svg" alt>More Items For You</h2>
    <a href="#">View More <i class="fas fa-arrow-right"></i></a>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p2.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type hot">Hot</span>
    <a href="shop-single.html"><img src="/assets/img/product/p5.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type oos">Out Of Stock</span>
    <a href="shop-single.html"><img src="/assets/img/product/p9.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type discount">10% Off</span>
    <a href="shop-single.html"><img src="/assets/img/product/p22.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <del>$120.00</del>
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type oos">Out Of Stock</span>
    <a href="shop-single.html"><img src="/assets/img/product/p25.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type new">New</span>
    <a href="shop-single.html"><img src="/assets/img/product/p26.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <a href="shop-single.html"><img src="/assets/img/product/p27.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
    <div class="product-item">
    <div class="product-img">
    <span class="type">Trending</span>
    <a href="shop-single.html"><img src="/assets/img/product/p30.png" alt></a>
    <div class="product-action-wrap">
    <div class="product-action">
    <a href="#" data-bs-toggle="modal" data-bs-target="#quickview" data-tooltip="tooltip" title="Quick View"><i class="far fa-eye"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Wishlist"><i class="far fa-heart"></i></a>
    <a href="#" data-tooltip="tooltip" title="Add To Compare"><i class="far fa-arrows-repeat"></i></a>
    </div>
    </div>
    </div>
    <div class="product-content">
    <h3 class="product-title"><a href="shop-single.html">Bluetooth Earphones</a></h3>
    <div class="product-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="far fa-star"></i>
    </div>
    <div class="product-bottom">
    <div class="product-price">
    <span>$100.00</span>
    </div>
    <button type="button" class="product-cart-btn" data-bs-placement="left" data-tooltip="tooltip" title="Add To Cart">
    <i class="far fa-shopping-bag"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="testimonial-area bg py-90">
    <div class="container">
    <div class="row">
    <div class="col-lg-6 mx-auto">
    <div class="site-heading text-center">
    <span class="site-title-tagline">Testimonials</span>
    <h2 class="site-title">What Our Client <span>Say's</span></h2>
    </div>
    </div>
    </div>
    <div class="testimonial-slider owl-carousel owl-theme">
    <div class="testimonial-item">
    <div class="testimonial-author">
    <div class="testimonial-author-img">
    <img src="/assets/img/testimonial/01.jpg" alt>
    </div>
    <div class="testimonial-author-info">
    <h4>Sylvia H Green</h4>
    <p>Customer</p>
    </div>
    </div>
    <div class="testimonial-quote">
    <p>
    There are many variations of long passages available but the content majority have
    suffered to the editor page when looking at its layout alteration in some injected.
    </p>
    </div>
    <div class="testimonial-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    </div>
    <div class="testimonial-quote-icon"><img src="/assets/img/icon/quote.svg" alt></div>
    </div>
    <div class="testimonial-item">
    <div class="testimonial-author">
    <div class="testimonial-author-img">
    <img src="/assets/img/testimonial/02.jpg" alt>
    </div>
    <div class="testimonial-author-info">
    <h4>Gordo Novak</h4>
    <p>Customer</p>
    </div>
    </div>
    <div class="testimonial-quote">
    <p>
    There are many variations of long passages available but the content majority have
    suffered to the editor page when looking at its layout alteration in some injected.
    </p>
    </div>
    <div class="testimonial-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    </div>
    <div class="testimonial-quote-icon"><img src="/assets/img/icon/quote.svg" alt></div>
    </div>
    <div class="testimonial-item">
    <div class="testimonial-author">
    <div class="testimonial-author-img">
    <img src="/assets/img/testimonial/03.jpg" alt>
    </div>
    <div class="testimonial-author-info">
    <h4>Reid E Butt</h4>
    <p>Customer</p>
    </div>
    </div>
    <div class="testimonial-quote">
    <p>
    There are many variations of long passages available but the content majority have
    suffered to the editor page when looking at its layout alteration in some injected.
    </p>
    </div>
    <div class="testimonial-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    </div>
    <div class="testimonial-quote-icon"><img src="/assets/img/icon/quote.svg" alt></div>
    </div>
    <div class="testimonial-item">
    <div class="testimonial-author">
    <div class="testimonial-author-img">
    <img src="/assets/img/testimonial/04.jpg" alt>
    </div>
    <div class="testimonial-author-info">
    <h4>Parker Jimenez</h4>
    <p>Customer</p>
    </div>
    </div>
    <div class="testimonial-quote">
    <p>
    There are many variations of long passages available but the content majority have
    suffered to the editor page when looking at its layout alteration in some injected.
    </p>
    </div>
    <div class="testimonial-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    </div>
    <div class="testimonial-quote-icon"><img src="/assets/img/icon/quote.svg" alt></div>
    </div>
    <div class="testimonial-item">
    <div class="testimonial-author">
    <div class="testimonial-author-img">
    <img src="/assets/img/testimonial/05.jpg" alt>
    </div>
    <div class="testimonial-author-info">
    <h4>Heruli Nez</h4>
    <p>Customer</p>
    </div>
    </div>
    <div class="testimonial-quote">
    <p>
    There are many variations of long passages available but the content majority have
    suffered to the editor page when looking at its layout alteration in some injected.
    </p>
    </div>
    <div class="testimonial-rate">
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    <i class="fas fa-star"></i>
    </div>
    <div class="testimonial-quote-icon"><img src="/assets/img/icon/quote.svg" alt></div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="blog-area py-90">
    <div class="container">
    <div class="row">
    <div class="col-lg-6 mx-auto">
    <div class="site-heading text-center">
    <span class="site-title-tagline">Our Blog</span>
    <h2 class="site-title">Latest News & <span>Blog</span></h2>
    </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-6 col-lg-4">
    <div class="blog-item wow fadeInUp" data-wow-delay=".25s">
    <div class="blog-item-img">
    <img src="/assets/img/blog/01.jpg" alt="Thumb">
    </div>
    <div class="blog-item-info">
    <div class="blog-item-meta">
    <ul>
    <li><a href="#"><i class="far fa-user-circle"></i> By Alicia Davis</a></li>
    <li><a href="#"><i class="far fa-calendar-alt"></i> January 29, 2023</a></li>
    </ul>
    </div>
    <h4 class="blog-title">
    <a href="#">There are many variations of passage available majority suffered.</a>
    </h4>
    <p>There are many variations available the majority have suffered alteration randomised words.</p>
    <a class="theme-btn" href="#">Read More<i class="fas fa-arrow-right-long"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="blog-item wow fadeInDown" data-wow-delay=".25s">
    <div class="blog-item-img">
    <img src="/assets/img/blog/02.jpg" alt="Thumb">
    </div>
    <div class="blog-item-info">
    <div class="blog-item-meta">
    <ul>
    <li><a href="#"><i class="far fa-user-circle"></i> By Alicia Davis</a></li>
    <li><a href="#"><i class="far fa-calendar-alt"></i> January 29, 2023</a></li>
    </ul>
    </div>
    <h4 class="blog-title">
    <a href="#">There are many variations of passage available majority suffered.</a>
    </h4>
    <p>There are many variations available the majority have suffered alteration randomised words.</p>
    <a class="theme-btn" href="#">Read More<i class="fas fa-arrow-right-long"></i></a>
    </div>
    </div>
    </div>
    <div class="col-md-6 col-lg-4">
    <div class="blog-item wow fadeInUp" data-wow-delay=".25s">
    <div class="blog-item-img">
    <img src="/assets/img/blog/03.jpg" alt="Thumb">
    </div>
    <div class="blog-item-info">
    <div class="blog-item-meta">
    <ul>
    <li><a href="#"><i class="far fa-user-circle"></i> By Alicia Davis</a></li>
    <li><a href="#"><i class="far fa-calendar-alt"></i> January 29, 2023</a></li>
    </ul>
    </div>
    <h4 class="blog-title">
    <a href="#">There are many variations of passage available majority suffered.</a>
    </h4>
    <p>There are many variations available the majority have suffered alteration randomised words.</p>
    <a class="theme-btn" href="#">Read More<i class="fas fa-arrow-right-long"></i></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="newsletter-area pt-60 pb-60">
    <div class="container">
    <div class="row">
    <div class="col-lg-6 mx-auto">
    <div class="newsletter-content">
    <h3>Get <span>20%</span> Off Discount Coupon</h3>
    <p>By Subscribe Our Newsletter</p>
    <div class="subscribe-form">
    <form action="#">
    <input type="email" class="form-control" placeholder="Your Valid Email Address">
    <button class="theme-btn" type="submit">
    Subscribe <i class="far fa-paper-plane"></i>
    </button>
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="newsletter-img-1">
    <img src="/assets/img/newsletter/01.png" alt>
    </div>
    <div class="newsletter-img-2">
    <img src="/assets/img/newsletter/02.png" alt>
    </div>
    </div>
    
    </main>
</template>

<script>

import { defineComponent } from 'vue';
import axios from "axios"
import mainJs from "../main.js"

import { load } from 'recaptcha-v3'

export default {
  name: 'HomePage',
  props: {

  },
  methods: {
   
  },
  mounted() {
    $('.hero-slider').owlCarousel({
        loop: true,
        nav: true,
        dots: true,
        margin: 0,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 5000,
        items: 1,
        navText: ["<i class='far fa-angle-left'></i>", "<i class='far fa-angle-right'></i>"],
        onInitialized: function(event) {
            var $firstAnimatingElements = $('.hero-slider .owl-item').eq(event.item.index).find("[data-animation]");
            doAnimations($firstAnimatingElements);
        },
        onChanged: function(event) {
            var $firstAnimatingElements = $('.hero-slider .owl-item').eq(event.item.index).find("[data-animation]");
            doAnimations($firstAnimatingElements);
        }
    });

    function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationDuration = $this.data('duration');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay,
                'animation-duration': $animationDuration,
                '-webkit-animation-duration': $animationDuration,
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }
    $('.hero-item-slider').owlCarousel({
        items: 4,
        loop: true,
        margin: 10,
        smartSpeed: 400,
        nav: true,
        autoplay: false,
        autoplayHoverPause: true,
        dots: false,
        navText: ["<i class='far fa-angle-left'></i>", "<i class='far fa-angle-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    });
    $('.product-slider').owlCarousel({
        items: 4,
        loop: true,
        margin: 20,
        smartSpeed: 400,
        nav: true,
        autoplay: false,
        autoplayHoverPause: true,
        dots: false,
        navText: ["<i class='far fa-angle-left'></i>", "<i class='far fa-angle-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    });
    $('.testimonial-slider').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            },
            1400: {
                items: 4
            }
        }
    });
    $('.brand-slider').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 6
            }
        }
    });
    $('.category-slider').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 4
            },
            1000: {
                items: 6
            },
            1200: {
                items: 7
            },
            1400: {
                items: 8
            }
        }
    });
    $('.instagram-slider').owlCarousel({
        loop: true,
        margin: 20,
        nav: false,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            1000: {
                items: 5
            }
        }
    });

    $(window).on('load', function() {
        setTimeout(function() {
            $("#popup-banner").modal("show");
        }, 3000)
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
