import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios"

import md5 from 'md5';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

library.add(fas,fab)


window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;


const urlApiDev = 'https://api.gradinaflorilor.ro/ApiRequests.php'
const urlApiProd = 'https://api.gradinaflorilor.ro/ApiRequests.php'
const urlToken = 'https://api.gradinaflorilor.ro/RequestTokenKeySecurity'

const postRequest = async function(requestData) {

  let dt = new Date();
  let year  = dt.getFullYear();
  let month = (dt.getMonth() + 1).toString().padStart(2, "0");
  let day   = dt.getDate().toString().padStart(2, "0");
  let hour   = dt.getHours().toString().padStart(2, "0");

  let formattedDate = day+'-'+month+'-'+year+'-'+hour;

  let encryptedKey = md5(formattedDate)

  let userToken = localStorage.getItem("userToken");
   
  var response = "";

  try {
    const tokenResponse = await axios.post(urlToken, { action: "generateTokenKey", 'encryptedKey': encryptedKey});
    const tokenKey = tokenResponse.data;

    if (tokenKey) {
      requestData.tokenKey = tokenKey;

      requestData.rememberToken = userToken;
     
      const apiResponse = await axios.post(urlApiProd, requestData);
      // console.log(apiResponse.data);
      if(apiResponse.data === 9 || (!apiResponse.data && apiResponse.data != 0)) {
      
        localStorage.removeItem("userToken");
        window.location.reload();
      } else {
        response = apiResponse.data;
      }
      
    }
  } catch (error) {
    console.error('Error in postRequest:', error);
    throw error; // Rethrow the error to handle it outside of this function if needed
  }

  return response;
}


export default {
  components: {
     
  },
    // php_url: process.env.NODE_ENV === 'development' ? urlApiDev : urlApiProd
    postRequest: postRequest,
    php_url:  urlApiProd,
    php_url_token: urlToken
  }
  

const app = createApp(App);

// Registering global components
app.component('fa', FontAwesomeIcon);


// Using plugins
app.use(router);

// Mounting the app
app.mount('#app');

// createApp(App).component('fa',FontAwesomeIcon;"v-select", vSelect).use(router).mount('#app')
