<template>
    <div class="user-card">
        <h4 class="user-card-title">Summary</h4>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="dashboard-widget color-1">
              <div class="dashboard-widget-info">
                <h1>50</h1>
                <span>Pending Orders</span>
              </div>
              <div class="dashboard-widget-icon">
                <i class="fal fa-list"></i>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="dashboard-widget color-2">
              <div class="dashboard-widget-info">
                <h1>250</h1>
                <span>Completed Orders</span>
              </div>
              <div class="dashboard-widget-icon">
                <i class="fal fa-layer-group"></i>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="dashboard-widget color-3">
              <div class="dashboard-widget-info">
                <h1>$15.80k</h1>
                <span>My Balance</span>
              </div>
              <div class="dashboard-widget-icon">
                <i class="fal fa-wallet"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
  
  <script>
  import { defineComponent } from "vue";
  
  export default {
    name: "Dashboard",
    props: {},
    methods: {},
    mounted() {},
  };
  </script>
  
  <style></style>
  