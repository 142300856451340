<template>
 <Header/>
 <HomePage/>
 <Footer/>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue'

import Header from '@/components/header_footer/Header.vue'
import Footer from '@/components/header_footer/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    HomePage,Header,Footer
  }
}
</script>
