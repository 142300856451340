<template>
    <div v-show="show" class="overlay transition">

      <div class="dialog">
  
        <div class="dialog__content">
          <h2 class="dialog__title" v-text="title"></h2>
          <hr />
          <div class="dialog__description" >
            <div v-if="message" :class="errorClass ? 'alert-danger' : 'alert-success'" class="alert my-3 d-flex mx-auto align-items-center justify-content-center message" role="alert">
              {{message}}
             
            </div>
            <div class="row w-100 px-5 mt-5">
              <div class="col-lg-5"> 
             
                <div style="display: grid;place-items: center;">
                  <label class="fs-5 text-center">
                    Nume atribut
                  </label>
                  <input type="text" v-model="attrName" class="form-control mt-1">
                </div>
                
             
              </div>
              <div class="col-lg-5"> 
                <div class="mx-auto" style="display: grid;place-items: center;width: 150px;">
                
                  <label class="fs-5 text-center">
                    Status atribut
                  </label>
                  <select v-model="statusAttrId" style="overflow-y: auto;" class="form-select w-100 mt-1 text-center">
                    <option v-for="status in statuses" :key="status.id" :value="status.id" :selected="statusAttrId === status.id">{{status.text}}</option>
                  </select>

                </div>
              </div>
              <div class="col-lg-2"> 
                <div style="display: grid;place-items: center;">
                  <label class="fs-5">&nbsp;</label>
                  <button class="btn btn-success" @click="saveAttribute">{{addButtonLabel}}</button>
                </div>
              </div>
            
           
          </div>
        </div>
        <hr style="margin-top: -12px;">
        <div class="dialog__footer">
          <button @click="cancel" class="btn btn-danger">{{noButton}}</button>
          <!-- <button @click="confirm" class="btn btn-info">{{okButton}}</button> -->
        </div>
  
      </div>
  
    </div>
  </div>
  </template>
  
  <script>
    
    import axios from "axios"
    import mainJs from "../../main.js"

  export default {
    name: "AddAttribute",
    data() {
        return {
          addButtonLabel: "Adaugă",
          statusAttrId: "1",
          attrName: "",
          message: "",
          errorClass: false,
          statuses: [
            {id: "1",text: "Activ"},
            {id: "2",text: "Dezactivat"},
          ]
        }
      },
      props: ['okButton','noButton','show', 'title', 'description', 'cancel', 'confirm', 'attributeId', 'attributeName', 'categStatus'],
      methods: {
    
        saveAttribute() {

          if(!this.attrName.trim()) {
            this.message = "Completează atributul!"
            this.errorClass = true
          } else {
            this.message = ""
            this.errorClass = false
          }

          console.log(this.statusAttrId);

          if(!this.message) {
            let requestData = 
            {
              action:"saveAttribute ",
              attribute_id: this.attributeId,
              attribute: this.attributeName,
              status: this.statusAttrId
            }


            mainJs.postRequest(requestData).then((res) => {
              // console.log(res);
            
              if(res != 0) {
               
                if(this.categId != null) {
                  this.message = "Atributul a fost modificat cu success!";
                } else {
                  this.message = "Atribut adaugată cu success!";
                }
                this.errorClass = false
              } else if(res == 0) {
                this.message = "Numele atributului există deja!";
                this.errorClass = true
              } else {
                this.message = "A apărut o eroare!";
                this.errorClass = true
              }
            })
          }
 
        },
        resetValues(){
          this.message = ""
          this.errorClass = false
        }
        
      },
      watch: {

      },
      beforeMount() {
      
      },

      mounted() {
       
        // if(this.parentId != 0 && this.parentId != null) {
        //   this.parentCategId = this.parentId
        // }

        // if(this.categId != null) {
        //   this.addButtonLabel = "Salvează"
        // }
       
        
        if(this.attributeName) {
          this.attrName = this.attributeName
        }
        // this.statusCategId = this.categStatus
        // this.getCategories()
      }
        
  }
  </script>

<style scoped>

  @keyframes fadeInAnimation {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }

    .transition {
      animation: fadeInAnimation ease 1s
    }

    .message {
      height: 5vw;
      width: 40%;
      font-size: 1vw;
      line-height: 91%;
      text-align: center;
    }

    .successMessage {
      height: 5vw;
      width: 40%;
      font-size: 1vw;
      line-height: 91%;
      text-align: center;
    }


    .overlay {
      --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
      --tw-bg-opacity: 0.5;
      height: 100%;
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 10;
    }
    .dialog {
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      border-radius: 0.75rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 9.5rem;
      min-width: 352px;
      width: 50%;
    }
    
    .dialog__content {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .dialog__title {
      font-weight: bold;
      font-size: 2rem;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
      --tw-text-opacity: 1;
      color: #be3c15;
      text-align: center;
    }
    .dialog__description {
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding: 0 0;
      margin-bottom: 1rem;
      color: green;
      text-align: justify;
      --tw-text-opacity: 1;
      color: rgba(107, 114, 128, var(--tw-text-opacity));
      overflow-y: auto;
      min-height:350px;
    }
    .dialog__footer {
      display: flex;
      column-gap: 46%;
      justify-content: center;
    
      padding-bottom: 1rem;
    }
    .dialog__cancel {
      border: none;
      border-radius: 0.5rem;
      font-weight: bold;
    }
   

</style>