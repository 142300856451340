<template>
  <Header/>

  <main class="main">

    <div class="site-breadcrumb">
    <div class="site-breadcrumb-bg" style="background: url(/assets/img/breadcrumb/01.jpg)"></div>
    <div class="container">
    <div class="site-breadcrumb-wrap">
    <h4 class="breadcrumb-title">Login</h4>
    <ul class="breadcrumb-menu">
    <li><a href="index.html"><i class="far fa-home"></i> Home</a></li>
    <li class="active">Login</li>
    </ul>
    </div>
    </div>
    </div>
    
    
    <div class="login-area py-100">
    <div class="container">
    <div class="col-md-7 col-lg-5 mx-auto">
    <div class="login-form">
    <div class="login-header">
    <img src="assets/img/logo/logo.png" alt>
    <p>Login with your goemart account</p>
    </div>
    <form action="#">

   

    <div class="form-group">
      <div v-if="error == 1" style="height: 20px;width:300px;" class="alert alert-danger my-3 d-flex mx-auto align-items-center justify-content-center" role="alert">
        Credențialele nu sunt corecte!
      </div>
    <label>Email Address</label>
    <input type="email" class="form-control" v-model="email" placeholder="Your Email">
    </div>
    <div class="form-group">
    <label>Password</label>
    <input type="password" class="form-control" v-model="password" placeholder="Your Password">
    </div>
    <div class="d-flex justify-content-between mb-4">
    <div class="form-check">
    <input class="form-check-input" type="checkbox" value id="remember">
    <label class="form-check-label" for="remember">
    Remember Me
    </label>
    </div>
    <a href="forgot-password.html" class="forgot-pass">Forgot Password?</a>
    </div>
    <div class="d-flex align-items-center">
    <button type="submit" @click.prevent="Login()" class="theme-btn"><i class="far fa-sign-in"></i> Login</button>
    </div>
    </form>
    <div class="login-footer">
    <p>Don't have an account? <a href="register.html">Register.</a></p>
    <div class="social-login">
    <span class="social-divider">or</span>
    <p>Continue with social media</p>
    <div class="social-login-list">
    <a href="#" class="fb-auth"><i class="fab fa-facebook-f"></i> Facebook</a>
    <a href="#" class="gl-auth"><i class="fab fa-google"></i> Google</a>
    <a href="#" class="tw-auth"><i class="fab fa-twitter"></i> Twitter</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="newsletter-area pt-60 pb-60">
    <div class="container">
    <div class="row">
    <div class="col-lg-6 mx-auto">
    <div class="newsletter-content">
    <h3>Get <span>20%</span> Off Discount Coupon</h3>
    <p>By Subscribe Our Newsletter</p>
    <div class="subscribe-form">
    <form action="#">
    <input type="email" class="form-control" placeholder="Your Valid Email Address">
    <button class="theme-btn" type="submit">
    Subscribe <i class="far fa-paper-plane"></i>
    </button>
    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div class="newsletter-img-1">
    <img src="assets/img/newsletter/01.png" alt>
    </div>
    <div class="newsletter-img-2">
    <img src="assets/img/newsletter/02.png" alt>
    </div>
    </div>
    
    </main>

    <Footer/>

</template>

<script>
// @ is an alias to /src

import Header from '@/components/header_footer/Header.vue'
import Footer from '@/components/header_footer/Footer.vue'

import axios from "axios"
import mainJs from "../main.js"

export default {
  name: 'LoginUser',
  components: {
    Header,Footer
  },
  data () {
    return {
       email: "",
       password: "",
       error: ""
    }
  },
  methods: {
    Login() {

    

      let requestData = 
      {
        action:"userCklogin",
        email: this.email,
        password: this.password
      }

       mainJs.postRequest(requestData).then((res) => {
       
        if(res != 0) {
          localStorage.setItem("userToken", res);
          window.location.pathname = '/user-dashboard';
          this.error = 0;
        } else {
          this.error = 1;
        }
      })
    }
  },
  mounted() {
    $(".main-category").addClass("hide-category");
  }
}
</script>

<style>


</style>
