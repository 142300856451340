<template>
  <AddCategory v-if="showAddCategory" :show="showAddCategory" 
    okButton="Ok"
    noButton="Închide"
    :cancel="closeAddCategory"
    confirm="confirm"
    title="Adaugă categorii!"
    :categId=categId
    :parentId=parentId
    :categName=categName
    :categStatus=categStatus
    
     />

     <ConfirmDialog v-if="showDeleteCategory" :show="showDeleteCategory" 
     okButton="Da"
     noButton="Închide"
     :cancel="closeDeleteCategory"
     :confirm="DeleteCategory"
     title="Atenție !!!"
     :description=description
     
      />

      <MessageDialog v-if="showMessageDialog" :show="showMessageDialog" 
      okButton="Ok"
      noButton="Închide"
      :cancel="closeMessageDialog"
      title="Atenție !!!"
      :description=description
      
       />

     <div class="user-wrapper">
      <div class="row">
        <div class="col-lg-12">
          
          <div class="user-card user-product">
            <div class="user-card-header">
              <h4 class="user-card-title">{{categType1}}</h4>
              <div class="user-card-header-right">
                
                <a href="#" class="theme-btn" @click.prevent="AddCategory('')">Adaugă Categorii</a>
              </div>
            </div>
            <div class="table-responsive">
             
              <table class="table table-borderless text-nowrap">
                <thead>
                  <tr>
                    <th><a href="#" @click.prevent="goBack"class="btn btn-danger me-4">Înapoi</a> 
                      <span v-if="parentCategName != 'Fără'">{{categType2}} {{parentCategName}}</span>
                      <span v-else>{{categType2}}</span>
                    </th>
                    <th class="text-center">Părinte</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Acțiune</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="categ in categories" :key="categ.id" :value="categ.id" :parent="categ.parent">
                    <td>
                      <div class="table-list-info">
                        <a :href="`/user-dashboard/categorii/${categ.id}`">
                          <div class="table-list-img">
                            <img
                              src="/assets/img/product/p1.png"
                              alt
                            />
                          </div>
                          <div class="table-list-content">
                            <h6>{{categ.name}}</h6>
                           
                          </div>
                        </a>
                      </div>
                    </td>
                    <td class="text-center"> {{parentCategName}}</td>
                    <td class="text-center">
                      <span v-if="categ.status == 1" class="badge badge-success">Activă</span>
                      <span v-if="categ.status == 2" class="badge badge-danger">Dezactivată</span>
                    </td>
                    <td class="text-center">
                      <!-- @click.prevent="getSubcategory(categ.id,categ.name)" -->
                      <a :href="`/user-dashboard/categorii/${categ.id}`"
                        class="btn btn-outline-secondary btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Details"
                        ><i class="far fa-eye"></i
                      ></a>
                      <a @click.prevent="AddCategory(categ.id,categ.parent,categ.name,categ.status)"
                        href="#"
                        class="btn btn-outline-secondary btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Edit"
                        ><i class="far fa-pen"></i
                      ></a>
                      <a @click.prevent="DeleteCategoryModal(categ.id,categ.name)"
                        href="#"
                        class="btn btn-outline-danger btn-sm rounded-2"
                        data-tooltip="tooltip"
                        title="Șterge categoria"
                        ><i class="far fa-trash-can"></i
                      ></a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>

 
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios"
import mainJs from "../../main.js"

import AddCategory from '../modals/AddCategory.vue'
import ConfirmDialog from '../modals/ConfirmDialog.vue'
import MessageDialog from '../modals/MessageDialog.vue'

export default {
  components: {AddCategory,ConfirmDialog,MessageDialog},
  // emits: ["parameters-sent"],
  inheritAttrs: false,
  name: "CategoriiDashboard",
  data() {
        return {
          showAddCategory: false,
          showDeleteCategory: false,
          showMessageDialog: false,
          categType1: "",
          categType2: "",
          description: "",
          categId: null,
          parentId: "",
          categName: "",
          parentCategName: "",
          categStatus: "1",
          categories: []
  
        }
      },
  
  props: ['idCateg','categoryName'],
  methods: {
    // getSubcategory(idCateg,categName) {
     
    //   let subCategParams = {
    //         component: 'SubCategorii',
    //         idCateg: idCateg,
    //         categoryName: categName
    //       }
    //   this.$emit('parameters-sent', subCategParams);
    // },
    goBack() {
      window.history.back();
    },
    getCategories() {
     
          let requestData = 
            {
              action:"getCategories",
              parent_categ_id: this.idCateg,
            }

          mainJs.postRequest(requestData).then((res) => {
            // let filteredArray = res.categories.filter(item => item.name != this.categName);

            this.categories = res.categories
            if(res.parentName) {
              this.parentCategName = res.parentName
            } else {
              this.parentCategName = "Fără"
            }
           

            // console.log(this.categories);
          })
        },
    AddCategory(id,parent,name,status) {
    
          this.showAddCategory = true
          if(!id) {
            this.categId = null;
            this.parentId = null;
            this.categName = "";
            this.categStatus = "1";
          } else {
            this.categId = id;
            this.parentId = parent;
            this.categName = name;
            this.categStatus = status;
          }
        },
        DeleteCategoryModal(id,name) {
    
          this.showDeleteCategory = true
          this.description = "Sigur dorești să ștergi categoria <br><span style='color: red;'>" + name + "</span> ?"
          if(!id) {
            this.categId = null;
            this.categName = "";
          } else {
            this.categId = id;
            this.categName = name;
          }
        },
        DeleteCategory() {
          
          this.showDeleteCategory = false
          if(this.categId) {
            let requestData = 
            {
              action:"deleteCategory",
              categ_id: this.categId
            }

            mainJs.postRequest(requestData).then((res) => {
              if(res == 1) {
                this.getCategories()
              } else if(res == 2) {
                this.description = "Categoria <span style='color: red;'>" + this.categName + "</span> are subcategorii!"
                this.showMessageDialog = true
              }
            })
          }
         
        },
    closeAddCategory() {
      this.showAddCategory = false
      this.getCategories()
    },
    closeDeleteCategory() {
      this.showDeleteCategory = false
    },
    closeMessageDialog() {
      this.showMessageDialog = false
    },
  
  },
  mounted() {
  
    if(this.idCateg) {
      // this.categId = this.idCateg
      this.categType1 = "Subcategorii";
      this.categType2 = "Subcategoria";
    } else {
      this.categType1 = "Categorii";
      this.categType2 = "Categoria";
    }
    this.getCategories()
  },
};
</script>

<style></style>
