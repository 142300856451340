<template>

    <div class="preloader">
    <div class="loader-ripple">
    <div></div>
    <div></div>
    </div>
    </div>

  <header class="header">

    <div class="header-top">
    <div class="container">
    <div class="header-top-wrapper">
    <div class="row">
    <div class="col-12 col-md-6 col-lg-6 col-xl-5">
    <div class="header-top-left">
    <ul class="header-top-list">
    <li><a href="/cdn-cgi/l/email-protection#8ae3e4ece5caeff2ebe7fae6efa4e9e5e7"><i class="far fa-envelopes"></i>
    <span class="__cf_email__" data-cfemail="4a23242c250a2f322b273a262f64292527">[email&#160;protected]</span></a></li>

    <li><a href="tel:+21236547898"><i class="far fa-headset"></i> +2 123 654
    7898</a></li>
    <li class="help"><a href="#"><i class="far fa-comment-question"></i> Need Help?</a></li>
    </ul>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6 col-xl-7">
    <div class="header-top-right">
    <ul class="header-top-list">
    <li><a href="#"><i class="far fa-alarm-clock"></i> Daily Deal</a></li>
    <li class="account"><a href="#"><i class="far fa-user-vneck"></i> Account</a></li>
    <li class="login"><a href="#"><i class="far fa-arrow-right-to-arc"></i> Login</a></li>
    <li>
    <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="far fa-usd"></i> USD
    </a>
    <div class="dropdown-menu">
    <a class="dropdown-item" href="#">USD</a>
    <a class="dropdown-item" href="#">EUR</a>
    <a class="dropdown-item" href="#">AUD</a>
    <a class="dropdown-item" href="#">CUD</a>
    </div>
    </div>
    </li>
    <li>
    <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="far fa-globe-americas"></i> EN
    </a>
    <div class="dropdown-menu">
    <a class="dropdown-item" href="#">EN</a>
    <a class="dropdown-item" href="#">FR</a>
    <a class="dropdown-item" href="#">DE</a>
    <a class="dropdown-item" href="#">RU</a>
    </div>
    </div>
    </li>
    <li class="social">
    <div class="header-top-social">
    <span>Follow Us: </span>
    <a href="#"><i class="fab fa-facebook"></i></a>
    <a href="#"><i class="fab fa-twitter"></i></a>
    <a href="#"><i class="fab fa-instagram"></i></a>
    <a href="#"><i class="fab fa-linkedin"></i></a>
    </div>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <div class="header-middle">
    <div class="container">
    <div class="row align-items-center">
    <div class="col-5 col-lg-3 col-xl-3">
    <div class="header-middle-logo">
    <a class="navbar-brand" href="index.html">
    <img src="/assets/img/logo/logo.png" alt="logo">
    </a>
    </div>
    </div>
    <div class="d-none d-lg-block col-lg-6 col-xl-5">
    <div class="header-middle-search">
    <form action="#">
    <div class="search-content">
    <select class="select">
    <option value>All Category</option>
    <option value="1">Today's Hot Deals</option>
    <option value="2">Fashion & Accessories</option>
    <option value="3">Electronics</option>
    <option value="4">Grocery & Market</option>
    <option value="5">Music</option>
    <option value="6">Home & Furniture</option>
    <option value="7">Toy & Video Games</option>
    <option value="8">Gifts</option>
    <option value="9">Babies & Moms</option>
    <option value="10">Sports & Outdoor</option>
    <option value="11">Health & Beauty</option>
    </select>
    <input type="text" class="form-control" placeholder="Search Here...">
    <button type="submit" class="search-btn"><i class="far fa-search"></i></button>
    </div>
    </form>
    </div>
    </div>
    <div class="col-7 col-lg-3 col-xl-4">
    <div class="header-middle-right">
    <ul class="header-middle-list">
    <li><a href="/login-area" class="list-item"><i class="far fa-user-circle"></i></a></li>
    <li><a href="#" class="list-item"><i class="far fa-arrows-rotate"></i><span>0</span></a></li>
    <li><a href="#" class="list-item"><i class="far fa-heart"></i><span>0</span></a></li>
    <li class="dropdown-cart">
    <a href="#" class="shop-cart list-item"><i class="far fa-shopping-bag"></i>
    <span>5</span></a>
    <div class="dropdown-cart-menu">
    <div class="dropdown-cart-header">
    <span>03 Items</span>
    <a href="#">View Cart</a>
    </div>
    <ul class="dropdown-cart-list">
    <li>
    <div class="dropdown-cart-item">
    <div class="cart-img">
    <a href="#"><img src="/assets/img/product/p47.png" alt="#"></a>
    </div>
    <div class="cart-info">
    <h4><a href="#">Xamaha R15 Red</a></h4>
    <p class="cart-qty">1x - <span class="cart-amount">$200.00</span></p>
    </div>
    <a href="#" class="cart-remove" title="Remove this item"><i class="far fa-times-circle"></i></a>
    </div>
    </li>
    <li>
    <div class="dropdown-cart-item">
    <div class="cart-img">
    <a href="#"><img src="/assets/img/product/p12.png" alt="#"></a>
    </div>
    <div class="cart-info">
    <h4><a href="#">Apple Blue Watch</a></h4>
    <p class="cart-qty">1x - <span class="cart-amount">$120.00</span></p>
    </div>
    <a href="#" class="cart-remove" title="Remove this item"><i class="far fa-times-circle"></i></a>
    </div>
    </li>
    <li>
    <div class="dropdown-cart-item">
    <div class="cart-img">
    <a href="#"><img src="/assets/img/product/p32.png" alt="#"></a>
    </div>
    <div class="cart-info">
    <h4><a href="#">Orange Sweater</a></h4>
    <p class="cart-qty">1x - <span class="cart-amount">$330.00</span></p>
    </div>
    <a href="#" class="cart-remove" title="Remove this item"><i class="far fa-times-circle"></i></a>
    </div>
    </li>
    </ul>
    <div class="dropdown-cart-bottom">
    <div class="dropdown-cart-total">
    <span>Total</span>
    <span class="total-amount">$650.00</span>
    </div>
    <a href="#" class="theme-btn">Checkout</a>
    </div>
    </div>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <div class="main-navigation">
    <nav class="navbar navbar-expand-lg">
    <div class="container position-relative">
    <a class="navbar-brand" href="index.html">
    <img src="/assets/img/logo/logo.png" class="logo-scrolled" alt="logo">
    </a>
    <div class="category-all">
    <button class="category-btn" type="button">
    <i class="far fa-grid-2-plus"></i><span>All Categories</span>
    </button>
    <ul class="main-category">
    <li>
    <a href="#">
    <img src="/assets/img/icon/shopping-bag.svg" alt>
    <span>New Arrivals</span><i class="far fa-angle-right"></i>
    </a>
    <ul class="sub-category">
    <li><a href="#"><img src="/assets/img/icon/dress.svg" alt><span>Accessories</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/laptop.svg" alt><span>Electronics</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/grocery.svg" alt><span>Grocery & Market</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/music.svg" alt><span>Music & Audio</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/furniture.svg" alt><span>Home & Furniture</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/toy.svg" alt><span>Toy & Video Games</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/gifts.svg" alt><span>Gifts</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/mother.svg" alt><span>Babies & Moms</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/beauty.svg" alt><span>Health & Beauty</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/sports.svg" alt><span>Sports & Outdoor</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/garden.svg" alt><span>Home & Garden</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/automotive.svg" alt><span>Automotive</span></a></li>
    </ul>
    </li>
    <li>
    <a href="#">
    <img src="/assets/img/icon/dress.svg" alt>
    <span>Fashion & Accessories</span><i class="far fa-angle-right"></i>
    </a>
    <div class="sub-category-mega">
    <div class="row">
    <div class="col-lg-4">
    <div class="category-single">
    <a href="#" class="category-title">Shop Kid's</a>
    <div class="category-img mb-3">
    <img src="/assets/img/category/01.jpg" alt="#">
    </div>
    <div class="category-link">
    <a href="#">Kids Toys</a>
    <a href="#">Kids Color Shape</a>
    <a href="#">Kids Travel Car</a>
    <a href="#">Kids Tent</a>
    </div>
    </div>
    </div>
    <div class="col-lg-4">
    <div class="category-single">
    <a href="#" class="category-title">Shop Men's</a>
    <div class="category-img mb-3">
    <img src="/assets/img/category/02.jpg" alt="#">
    </div>
    <div class="category-link">
    <a href="#">Watch</a>
    <a href="#">Hoodies</a>
    <a href="#">T-shirt</a>
    <a href="#">Formal Pant</a>
    </div>
    </div>
    </div>
    <div class="col-lg-4">
    <div class="category-single">
    <a href="#" class="category-title">Shop Women's</a>
    <div class="category-img mb-3">
    <img src="/assets/img/category/03.jpg" alt="#">
    </div>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </li>
    <li>
    <a href="#">
    <img src="/assets/img/icon/laptop.svg" alt>
    <span>Electronics</span><i class="far fa-angle-right"></i>
    </a>
    <div class="sub-category-mega">
    <div class="row">
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Man Cloths</h6>
    <div class="category-link">
    <a href="#">Kids Toys</a>
    <a href="#">Kids Color Shape</a>
    <a href="#">Kids Travel Car</a>
    <a href="#">Kids Tent</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Accessories</h6>
    <div class="category-link">
    <a href="#">Watch</a>
    <a href="#">Hoodies</a>
    <a href="#">T-shirt</a>
    <a href="#">Formal Pant</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Bags</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-img">
    <a href="#"><img src="/assets/img/category/04.jpg" alt></a>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Women Cloths</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Shoes</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Bags</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-img">
    <a href="#"><img src="/assets/img/category/05.jpg" alt></a>
    </div>
    </div>
    </div>
    </div>
    </li>
    <li>
    <a href="#">
    <img src="/assets/img/icon/grocery.svg" alt>
    <span>Grocery & Market</span><i class="far fa-angle-right"></i>
    </a>
    <div class="sub-category-mega">
    <div class="row">
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Man Cloths</h6>
    <div class="category-link">
    <a href="#">Kids Toys</a>
    <a href="#">Kids Color Shape</a>
    <a href="#">Kids Travel Car</a>
    <a href="#">Kids Tent</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Accessories</h6>
    <div class="category-link">
    <a href="#">Watch</a>
    <a href="#">Hoodies</a>
    <a href="#">T-shirt</a>
    <a href="#">Formal Pant</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Bags</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Women Cloths</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Man Cloths</h6>
    <div class="category-link">
    <a href="#">Kids Toys</a>
    <a href="#">Kids Color Shape</a>
    <a href="#">Kids Travel Car</a>
    <a href="#">Kids Tent</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Accessories</h6>
    <div class="category-link">
    <a href="#">Watch</a>
    <a href="#">Hoodies</a>
    <a href="#">T-shirt</a>
    <a href="#">Formal Pant</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Bags</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Women Cloths</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </li>
    <li><a href="#"><img src="/assets/img/icon/music.svg" alt><span>Music & Audio</span></a></li>
    <li>
    <a href="#">
    <img src="/assets/img/icon/furniture.svg" alt>
    <span>Home & Furniture</span><i class="far fa-angle-right"></i>
    </a>
    <div class="sub-category-mega">
    <div class="row">
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Man Cloths</h6>
    <div class="category-link">
    <a href="#">Kids Toys</a>
    <a href="#">Kids Color Shape</a>
    <a href="#">Kids Travel Car</a>
    <a href="#">Kids Tent</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Accessories</h6>
    <div class="category-link">
    <a href="#">Watch</a>
    <a href="#">Hoodies</a>
    <a href="#">T-shirt</a>
    <a href="#">Formal Pant</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-single">
    <h6 class="category-title-text">Bags</h6>
    <div class="category-link">
    <a href="#">Ladies Shirt</a>
    <a href="#">Ladies Sun Glass</a>
    <a href="#">Ladies Frog</a>
    <a href="#">Ladies Watch</a>
    </div>
    </div>
    </div>
    <div class="col-lg-3">
    <div class="category-img">
    <a href="#"><img src="/assets/img/category/04.jpg" alt></a>
    </div>
    </div>
    </div>
    </div>
    </li>
    <li><a href="#"><img src="/assets/img/icon/toy.svg" alt><span>Toy & Video Games</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/gifts.svg" alt><span>Gifts</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/mother.svg" alt><span>Babies & Moms</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/beauty.svg" alt><span>Health & Beauty</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/sports.svg" alt><span>Sports & Outdoor</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/garden.svg" alt><span>Home & Garden</span></a></li>
    <li><a href="#"><img src="/assets/img/icon/automotive.svg" alt><span>Automotive</span></a></li>
    </ul>
    </div>
    <div class="mobile-menu-right">
    <div class="search-btn">
    <button type="button" class="nav-right-link"><i class="far fa-search"></i></button>
    <div class="mobile-search-form">
    <form action="#">
    <input type="text" class="form-control" placeholder="Search Here...">
    <button type="submit"><i class="far fa-search"></i></button>
    </form>
    </div>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-mobile-icon"><i class="far fa-bars"></i></span>
    </button>
    </div>
    <div class="collapse navbar-collapse" id="main_nav">
    <ul class="navbar-nav">
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle active" href="#" data-bs-toggle="dropdown">Home</a>
    <ul class="dropdown-menu fade-down">
    <li><a class="dropdown-item" href="index.html">Home Demo 01</a></li>
    <li><a class="dropdown-item" href="index-2.html">Home Demo 02</a></li>
    <li><a class="dropdown-item" href="index-3.html">Home Demo 03</a></li>
    <li><a class="dropdown-item" href="index-4.html">Home Demo 04</a></li>
    <li><a class="dropdown-item" href="index-5.html">Home Demo 05</a></li>
    <li><a class="dropdown-item" href="index-6.html">Home Demo 06</a></li>
    <li><a class="dropdown-item" href="index-7.html">Home Demo 07</a></li>
    <li><a class="dropdown-item" href="index-8.html">Home Demo 08</a></li>
    <li><a class="dropdown-item" href="index-9.html">Home Demo 09</a></li>
    <li><a class="dropdown-item" href="index-10.html">Home Demo 10</a></li>
    </ul>
    </li>
    <li class="nav-item"><a class="nav-link" href="about.html">About</a></li>
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Pages</a>
    <ul class="dropdown-menu fade-down">
    <li><a class="dropdown-item" href="about.html">About Us</a></li>
    <li><a class="dropdown-item" href="become-seller.html">Become Seller</a></li>
    <li><a class="dropdown-item" href="brand.html">Brands</a></li>
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Category</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="category.html">Category One</a></li>
    <li><a class="dropdown-item" href="category-2.html">Category Two</a></li>
    <li><a class="dropdown-item" href="category-3.html">Category Three</a></li>
    <li><a class="dropdown-item" href="category-4.html">Category Four</a></li>
    </ul>
    </li>
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Authentication</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="login.html">Login</a></li>
    <li><a class="dropdown-item" href="register.html">Register</a></li>
    <li><a class="dropdown-item" href="forgot-password.html">Forgot Password</a>
    </li>
    </ul>
    </li>
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Extra Pages</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="404.html">404 Error</a></li>
    <li><a class="dropdown-item" href="coming-soon.html">Coming Soon</a></li>
    <li><a class="dropdown-item" href="return.html">Return Policy</a></li>
    <li><a class="dropdown-item" href="terms.html">Terms Of Service</a></li>
    <li><a class="dropdown-item" href="privacy.html">Privacy Policy</a></li>
    <li><a class="dropdown-item" href="mail-success.html">Mail Success</a></li>
    </ul>
    </li>
    <li><a class="dropdown-item" href="team.html">Our Team</a></li>
    <li><a class="dropdown-item" href="contact.html">Contact Us</a></li>
    <li><a class="dropdown-item" href="help.html">Help</a></li>
    <li><a class="dropdown-item" href="invoice.html">Invoices</a></li>
    <li><a class="dropdown-item" href="faq.html">Faq</a></li>
    <li><a class="dropdown-item" href="testimonial.html">Testimonials</a></li>
    </ul>
    </li>
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">User</a>
    <ul class="dropdown-menu fade-down">
    <li><a class="dropdown-item" href="user-dashboard.html">Dashboard</a></li>
    <li><a class="dropdown-item" href="user-profile.html">My Profile</a></li>
    <li><a class="dropdown-item" href="order-list.html">Order List</a></li>
    <li><a class="dropdown-item" href="order-detail.html">Order Details</a></li>
    <li><a class="dropdown-item" href="wishlist.html">My Wishlist</a></li>
    <li><a class="dropdown-item" href="add-address.html">Add Address</a></li>
    <li><a class="dropdown-item" href="address-list.html">Address List</a></li>
    <li><a class="dropdown-item" href="support-ticket.html">Support Tickets</a></li>
    <li><a class="dropdown-item" href="ticket-detail.html">Ticket Details</a></li>
    <li><a class="dropdown-item" href="track-order.html">Track My Order</a></li>
    <li><a class="dropdown-item" href="payment-method.html">Payment Methods</a></li>
    <li><a class="dropdown-item" href="add-payment.html">Add Payment</a></li>
    <li><a class="dropdown-item" href="user-message.html">Messages</a></li>
    <li><a class="dropdown-item" href="user-setting.html">Settings</a></li>
    </ul>
    </li>
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Vendor</a>
    <ul class="dropdown-menu fade-down">
    <li><a class="dropdown-item" href="vendor-dashboard.html">Dashboard</a></li>
    <li><a class="dropdown-item" href="vendors.html">All Vendors</a></li>
    <li><a class="dropdown-item" href="vendor-profile.html">Vendor Profile</a></li>
    <li><a class="dropdown-item" href="vendor-store.html">Vendor Store</a></li>
    <li><a class="dropdown-item" href="vendor-product.html">Products</a></li>
    <li><a class="dropdown-item" href="vendor-add-product.html">Add Product</a></li>
    <li><a class="dropdown-item" href="vendor-order.html">All Orders</a></li>
    <li><a class="dropdown-item" href="vendor-order-detail.html">Order Details</a></li>
    <li><a class="dropdown-item" href="vendor-payout.html">Payout</a></li>
    <li><a class="dropdown-item" href="vendor-transaction.html">Transaction</a></li>
    <li><a class="dropdown-item" href="vendor-notification.html">Notification</a></li>
    <li><a class="dropdown-item" href="vendor-message.html">Messages</a></li>
    <li><a class="dropdown-item" href="vendor-setting.html">Settings</a></li>
    </ul>
    </li>
    <li class="nav-item mega-menu dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Menu <span class="new">New</span></a>
    <div class="dropdown-menu fade-down">
    <div class="mega-content">
    <div class="container-fluid px-lg-0">
    <div class="row">
    <div class="col-12 col-lg-2">
    <h5 class="mega-menu-title">Fashion</h5>
    <ul class="mega-menu-item">
    <li><a class="dropdown-item" href="#">Ladies Shirt</a></li>
    <li><a class="dropdown-item" href="#">Mens T-shirt</a></li>
    <li><a class="dropdown-item" href="#">Ladies Sun Glass</a></li>
    <li><a class="dropdown-item" href="#">Crossbody Bags</a></li>
    <li><a class="dropdown-item" href="#">Ladies Watch</a></li>
    <li><a class="dropdown-item" href="#">Mens Shirt</a></li>
    </ul>
    </div>
    <div class="col-12 col-lg-2">
    <h5 class="mega-menu-title">Fashion</h5>
    <ul class="mega-menu-item">
    <li><a class="dropdown-item" href="#">Ladies Shirt</a></li>
    <li><a class="dropdown-item" href="#">Mens T-shirt</a></li>
    <li><a class="dropdown-item" href="#">Ladies Sun Glass</a></li>
    <li><a class="dropdown-item" href="#">Crossbody Bags</a></li>
    <li><a class="dropdown-item" href="#">Ladies Watch</a></li>
    <li><a class="dropdown-item" href="#">Mens Shirt</a></li>
    </ul>
    </div>
    <div class="col-12 col-lg-2">
    <h5 class="mega-menu-title">Fashion</h5>
    <ul class="mega-menu-item">
    <li><a class="dropdown-item" href="#">Ladies Shirt</a></li>
    <li><a class="dropdown-item" href="#">Mens T-shirt</a></li>
    <li><a class="dropdown-item" href="#">Ladies Sun Glass</a></li>
    <li><a class="dropdown-item" href="#">Crossbody Bags</a></li>
    <li><a class="dropdown-item" href="#">Ladies Watch</a></li>
    <li><a class="dropdown-item" href="#">Mens Shirt</a></li>
    </ul>
    </div>
    <div class="col-12 col-lg-2">
    <h5 class="mega-menu-title">Fashion</h5>
    <ul class="mega-menu-item">
    <li><a class="dropdown-item" href="#">Ladies Shirt</a></li>
    <li><a class="dropdown-item" href="#">Mens T-shirt</a></li>
    <li><a class="dropdown-item" href="#">Ladies Sun Glass</a></li>
    <li><a class="dropdown-item" href="#">Crossbody Bags</a></li>
    <li><a class="dropdown-item" href="#">Ladies Watch</a></li>
    <li><a class="dropdown-item" href="#">Mens Shirt</a></li>
    </ul>
    </div>
    <div class="col-12 col-lg-4">
    <div class="mega-menu-img">
    <a href="#"><img src="/assets/img/banner/mega-menu-banner.jpg" alt></a>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </li>
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Shop</a>
    <ul class="dropdown-menu fade-down">
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Shop Grid</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="shop-grid.html">Shop Grid One</a></li>
    <li><a class="dropdown-item" href="shop-grid-2.html">Shop Grid Two</a></li>
    <li><a class="dropdown-item" href="shop-grid-3.html">Shop Grid Three</a></li>
    </ul>
    </li>
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Shop List</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="shop-list.html">Shop List One</a></li>
    <li><a class="dropdown-item" href="shop-list-2.html">Shop List Two</a></li>
    <li><a class="dropdown-item" href="shop-list-3.html">Shop List Three</a></li>
    </ul>
    </li>
    <li><a class="dropdown-item" href="shop-search.html">Shop Search</a></li>
    <li><a class="dropdown-item" href="shop-cart.html">Shop Cart</a></li>
    <li class="dropdown-submenu">
    <a class="dropdown-item dropdown-toggle" href="#">Shop Checkout</a>
    <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="shop-checkout.html">Checkout One</a></li>
    <li><a class="dropdown-item" href="shop-checkout-2.html">Checkout Two</a></li>
    <li><a class="dropdown-item" href="shop-checkout-complete.html">Checkout Complete</a></li>
    </ul>
    </li>
    <li><a class="dropdown-item" href="shop-single.html">Shop Single One</a></li>
    <li><a class="dropdown-item" href="shop-single-2.html">Shop Single Two</a></li>
    <li><a class="dropdown-item" href="shop-compare.html">Shop Compare</a></li>
    </ul>
    </li>
    <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Blog</a>
    <ul class="dropdown-menu fade-down">
    <li><a class="dropdown-item" href="blog-grid.html">Blog Grid</a></li>
    <li><a class="dropdown-item" href="blog-grid-sidebar.html">Blog Grid Sidebar</a></li>
    <li><a class="dropdown-item" href="blog-single.html">Blog Single</a></li>
    <li><a class="dropdown-item" href="blog-single-sidebar.html">Blog Single Sidebar</a></li>
    </ul>
    </li>
    <li class="nav-item"><a class="nav-link" href="contact.html">Contact</a></li>
    </ul>
    <div class="nav-right">
    <div class="nav-right-btn">
    <a href="become-seller.html" class="theme-btn">Become Seller</a>
    </div>
    </div>
    </div>
    </div>
    </nav>
    </div>
    </header>
    
    
</template>

<script>

import { defineComponent } from 'vue';

export default {
  name: 'Header',
  props: {
    
  },
  methods: {
   
  },
  mounted() {
    $('.category-btn').on('click', function() {
        $(".main-category").toggle();
    });

    $('.select').niceSelect();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
