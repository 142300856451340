<template>
  <Header />

  <main class="main">
    <div class="site-breadcrumb">
      <div
        class="site-breadcrumb-bg"
        style="background: url(/assets/img/breadcrumb/01.jpg)"
      ></div>
      <div class="container">
        <div class="site-breadcrumb-wrap">
          <h4 class="breadcrumb-title">Dashboard</h4>
          <ul class="breadcrumb-menu">
            <li>
              <a href="index.html"><i class="far fa-home"></i> Home</a>
            </li>
            <li class="active">{{categType}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="user-area bg py-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="sidebar">
              <div class="sidebar-top">
                <div class="sidebar-profile-img">
                  <img src="/assets/img/account/user.jpg" alt />
                  <button type="button" class="profile-img-btn">
                    <i class="far fa-camera"></i>
                  </button>
                  <input type="file" class="profile-img-file" />
                </div>
                <h5>Antoni Jonson</h5>
                <p>
                  <a
                    href="mailto:antoni@example.com"
                    class="__cf_email__"
                    data-cfemail="bddcd3c9d2d3d4fdd8c5dcd0cdd1d893ded2d0"
                    >antoni@example.com</a
                  >
                </p>
              </div>
              <ul class="sidebar-list">
                <li>
                  <a href="/user-dashboard" :class="menuItemSelect === 'Dashboard' ? 'active' : ''"><i class="far fa-gauge-high"></i> Dashboard</a>
                </li>
                <!-- <li>
                  <a href="#" :class="menuItemSelect === 'Categorii' ? 'active' : ''" @click.prevent="activeComponent = 'Categorii';menuItemSelect = 'Categorii'"><i class="far fa-wallet"></i> Categorii</a>
                </li> -->
                <li>
                  <a href="/user-dashboard/categorii" :class="menuItemSelect === 'Categories' ? 'active' : ''" ><i class="far fa-wallet"></i> Categorii</a>
                </li>
                <li>
                  <a href="/user-dashboard/atribute" :class="menuItemSelect === 'Attributes' ? 'active' : ''" ><i class="far fa-wallet"></i> Atribute</a>
                </li>
                <li>
                  <a href="#"
                    ><i class="far fa-gear"></i> Setări
                    <span class="badge badge-danger">02</span></a
                  >
                </li>
                <li>
                  <a href="#" @click.prevent="Logout()"
                    ><i class="far fa-sign-out"></i> Logout</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="user-wrapper">


              <!-- Categorii Insert -->
              <!-- <component :is="activeComponent" @parameters-sent="receiveParameters" :idCateg="categId" :categoryName="categoryName"/> -->
              <component :is="activeComponent" :idCateg="categId" :categoryName="categoryName"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <Footer />
</template>

<script>
// @ is an alias to /src

import Header from "@/components/header_footer/Header.vue";
import Footer from "@/components/header_footer/Footer.vue";

import Dashboard from "@/components/user_dashboard/Dashboard.vue";
import Categories from "@/components/user_dashboard/Categories.vue";
import Attributes from "@/components/user_dashboard/Attributes.vue";

import axios from "axios";
import mainJs from "../main.js";

export default {
  name: "UserDashboard",
  components: {
    Header,Footer,Dashboard,Categories,Attributes
  },
  data() {
    return {
      activeComponent: "Dashboard",
      menuItemSelect: "Dashboard",
      categType: "Dashboard",
      categId: "",
      categoryName: ""
    };
  },
  props: ["tipComponenta","routeIdCateg"],
  // props: ["parameters-sent","tipComponenta","routeIdCateg"],
 
  methods: {
    // receiveParameters(parameters) {
     
    //   this.categId = parameters.idCateg;
    //   this.activeComponent = parameters.component
    //   this.categoryName = parameters.categoryName


    // },
    Logout() {
      let userToken = localStorage.getItem("userToken");

      let requestData = {
        action: "userLogout",
        rememberToken: userToken,
      };

      mainJs.postRequest(requestData).then((res) => {
        localStorage.removeItem("userToken");
        window.location.reload();

        // console.log(res);
       
      });
    },
  },
  mounted() {
    $(".main-category").addClass("hide-category");


    if(this.tipComponenta) {
      this.activeComponent = this.tipComponenta
      this.menuItemSelect = this.tipComponenta
      // console.log(this.tipComponenta);
      if(this.tipComponenta == "Categories") {
        this.categType = "Categorii"
      } else if(this.tipComponenta == "Atribute") {
        this.categType = "Atribute"
      }

    }

    if(this.routeIdCateg) {
      this.categId = this.routeIdCateg;
      this.categType = "Subcategorii"
    } 
  },
};
</script>

<style></style>
